import clsx from 'clsx';
import * as React from 'react';
import { FC } from 'react';

type TableOfContentWrapperProps = {};

export const TableOfContentWrapper: FC<TableOfContentWrapperProps> = ({
  children,
}) => {
  return (
    <div className="TableOfContentWrapper flex flex-col">
      <p>Table of content:</p>
      <TableOfContentItem id="article-title" rank={2}>
        Introduction
      </TableOfContentItem>
      {children}
    </div>
  );
};

type TableOfContentItemProps = {
  id: string;
  rank: number; // h1, h2, h3 ...
};

export const TableOfContentItem: FC<TableOfContentItemProps> = ({
  children,
  id,
  rank,
}) => {
  return (
    <a
      className={clsx(
        'TableOfContentItem cursor-pointer',
        rank === 2 && 'ml-0',
        rank === 3 && 'ml-2',
        rank === 4 && 'ml-4',
        rank === 5 && 'ml-6',
        rank === 6 && 'ml-8'
      )}
      href={`#${id}`}
    >
      {children}
    </a>
  );
};
