import { heading } from 'hast-util-heading';
import { headingRank } from 'hast-util-heading-rank';
import { Node } from 'unist';
import visit from 'unist-util-visit';

const extractHeaders = (_settings: {}) => (tree: Node) => {
  const headers: any = [];

  visit(tree, heading, (node: any) => {
    headers.push({
      ...node,
      properties: {
        ...node.properties,
        rank: headingRank(node),
      },
    });
  });

  return {
    children: headers,
    properties: {},
    tagName: 'div',
    type: 'element',
  };
};

export default extractHeaders;
