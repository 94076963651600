import Layout from '@layouts';
import { getErrorMessages, getInvalidFields } from '@lib';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { graphql } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import rehype2react from 'rehype-react';
import unified from 'unified';
import * as Yup from 'yup';

import Button from '@components/Button';
import SEO from '@components/SEO';
import BlogPostPreview from '@components/blog/BlogPostPreview';
import {
  TableOfContentItem,
  TableOfContentWrapper,
} from '@components/blog/TableOfContent';
import TextInput from '@components/form/TextInput';

import { BlogPost } from '@data/blog';

import { formatTimeUntilNow } from '@lib/format';
import extractHeaders from '@lib/unified/extractHeaders';

import './BlogPost.css';

const processor = unified().use(rehype2react, {
  createElement: React.createElement,
});

const tocProcessor = unified()
  .use(extractHeaders, {})
  .use(rehype2react, {
    createElement: React.createElement,
    components: {
      div: TableOfContentWrapper,
      h1: TableOfContentItem,
      h2: TableOfContentItem,
      h3: TableOfContentItem,
      h4: TableOfContentItem,
      h5: TableOfContentItem,
      h6: TableOfContentItem,
    },
  });

type BlogPostPageProps = {
  data: {
    ghostPost: BlogPost;
  };
};

const BlogPostPage: FC<BlogPostPageProps> = ({ data: { ghostPost } }) => {
  const {
    htmlAst,
    reading_time,
    slug,
    title,
    primary_author,
    primary_tag,
    published_at,
    updated_at,
    image,
    meta_description,
    relatedPosts,
  } = ghostPost;
  const path = `/blog/${slug}`;

  const tableOfContent = useMemo(() => {
    if (!htmlAst) return;

    return tocProcessor.stringify(tocProcessor.runSync(htmlAst));
  }, [htmlAst]);
  const [initialPositionTOCRef, isTOCInView] = useInView();

  const newsletterFormik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string()
        .required('Email field is required')
        .email('This is not a valid email.'),
    }),
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      try {
        console.log('Email send!');
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        if (errorMessages) alert(errorMessages.join('\n'));
        else console.error('[Newsletter] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Layout title={title} url={path}>
      <SEO
        title={title}
        description={meta_description}
        url={path}
        image={
          image
            ? {
                src: getSrc(image),
                width: getImage(image).width,
                height: getImage(image).height,
              }
            : undefined
        }
        blogPost={{
          description: meta_description,
          author: primary_author.name,
          datePublished: published_at,
          dateModified: updated_at,
        }}
      />
      <p className="lg:hidden font-emp font-bold text-base text-center mt-4">
        Media
      </p>

      <div className="BlogPost-Wrapper grid grid-cols-12 mt-4 lg:mt-10 gap-x-5">
        <div
          className={clsx(
            'hidden lg:block TableOfContent col-span-2 md:mt-60 ',
            !isTOCInView && 'TableOfContent--fixed'
          )}
        >
          <div ref={initialPositionTOCRef} />
          {tableOfContent}
        </div>
        <section className="BlogContent col-span-12 lg:col-span-8">
          <p className="hidden lg:block text-white text-xs mb-12">
            <span className="text-interaction-light">{primary_tag?.name}</span>
            <span className="mx-3">&#8226;</span>
            by{' '}
            <span className="text-interaction-light">
              {primary_author.name}
            </span>{' '}
            {formatTimeUntilNow(published_at)}
            <span className="mx-3">&#8226;</span>
            {reading_time} min read
          </p>

          <h1 id="article-title" className="title font-emp">
            {title}
          </h1>

          <p className="lg:hidden text-white text-xs">
            <span className="text-interaction-light">{primary_tag?.name}</span>
            <span className="mx-3">&#8226;</span>
            {reading_time} min read
            <br />
            by{' '}
            <span className="text-interaction-light">
              {primary_author.name}
            </span>{' '}
            {formatTimeUntilNow(published_at)}
          </p>

          <div className="container">
            <section className="BodySection">
              <div className="text-base font-emp">
                {processor.stringify(htmlAst)}
              </div>
            </section>
          </div>

          <hr className="hidden lg:block bg-interaction-statusDots mx-auto my-8 text-center w-2/3 h-0.5 text-interaction-statusDots" />
          <form
            onSubmit={newsletterFormik.handleSubmit}
            className="Newsletter hidden lg:flex bg-background-light mb-10 rounded-md px-8 py-6"
          >
            <div className="w-3/4">
              <p className="text-3xl font-emp font-bold mb-4">
                Subscribe to our
                <span className="block">monthly newsletter</span>
              </p>
              <div className="Newsletter-Action pb-2 flex">
                <TextInput
                  name={'email'}
                  formik={newsletterFormik}
                  placeholder={'Email address'}
                />
                <span>
                  <Button type="submit" name="submit" variant="primary">
                    Subscribe
                  </Button>
                </span>
              </div>
            </div>
          </form>
        </section>
        {/*<div className="hidden lg:block TableOfContent col-span-2 md:mt-60">*/}
        {/*  {tableOfContent}*/}
        {/*</div>*/}
      </div>
      <div className="SimilarArticles grid grid-rows-1 grid-cols-12 mb-10">
        <div className="hidden lg:block col-span-1" />
        <section className="body-section mt-16 col-span-12 lg:col-span-10">
          <div className="flex justify-between">
            <hr className="bg-interaction-statusDots w-1/12 lg:w-1/4 h-0.5 text-interaction-statusDots" />
            <h2 className="white text-base uppercase -mt-2.5 font-semibold font-emp opacity-60 tracking-widest text-center">
              Other similar articles
            </h2>
            <hr className="bg-interaction-statusDots w-1/12 lg:w-1/4 h-0.5  text-interaction-statusDots" />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-12 gap-y-12">
            {relatedPosts &&
              relatedPosts.map((post) => (
                <BlogPostPreview
                  key={post.slug}
                  similarArticles={relatedPosts && relatedPosts.length > 0}
                  className="max-2"
                  post={post}
                />
              ))}
          </div>
        </section>
        <div className="hidden lg:block col-span-1" />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    ghostPost(id: { eq: $id }) {
      reading_time
      htmlAst
      title
      slug
      meta_description
      excerpt
      published_at
      updated_at
      primary_tag {
        name
      }
      primary_author {
        name
      }
      relatedPosts {
        excerpt
        reading_time
        title
        slug
        published_at
        primary_author {
          name
        }
        primary_tag {
          name
        }
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 630, layout: FIXED)
        }
      }
    }
  }
`;

export default BlogPostPage;
